<template>
  <MDBContainer>
    <WarningBannerDemo v-if="isDemo" />
    <WarningBannerAlpha v-else />
    <Header />
    <MDBRow class="main-background">
      <slot />
    </MDBRow>
    <Footer />
    <CookieConsent />
    <LazyGlobalToast />
  </MDBContainer>
</template>

<script setup lang="ts">
import { MDBContainer, MDBRow } from "mdb-vue-ui-kit";
const isDemo = computed(() => projectId() === "deliverydovesdemo");
</script>
