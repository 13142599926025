<template>
  <MDBModal
    id="cookiesModal"
    v-model="modalOpen"
    frame
    position="bottom"
    tabindex="-1"
    labelledby="cookiesModal"
  >
    <MDBModalBody class="py-2">
      We use cookies and other technologies to improve your experience. Visit our
      <NuxtLink to="/policies" class="text-decoration-underline">policies page</NuxtLink>
      for more information.
    </MDBModalBody>
  </MDBModal>
</template>

<script setup lang="ts">
import { MDBModal, MDBModalBody } from "mdb-vue-ui-kit";

const modalOpen = ref(false);
const cookie = useCookie<boolean>("HasAcceptedCookies");
onMounted(() => {
  if (!cookie.value) {
    modalOpen.value = true;
    cookie.value = true;
  }
});
</script>
