<template>
  <MDBBtn color="danger" class="m-2" @click="logout">Log Out</MDBBtn>
</template>

<script setup lang="ts">
import { MDBBtn } from "mdb-vue-ui-kit";
import { signOut } from "firebase/auth";
const { $firebaseAuth } = useNuxtApp();

const logout = async () => {
  await signOut($firebaseAuth);
  navigateTo("/");
};
</script>
