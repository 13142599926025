<template>
  <MDBAlert v-if="sent" v-model="sentalert" dismiss :autohide="false" color="success" static>
    <strong>Sent!</strong>
    Your message has been sent!
  </MDBAlert>
  <form v-else class="needs-validation" @submit.prevent="submit">
    <h5>Contact Us</h5>

    <ClientOnly>
      <!-- Email -->
      <MDBInput
        white
        v-model="form.email"
        type="email"
        label="Email address"
        wrapper-class="my-4"
        required
        :invalid-feedback="error"
      />

      <!-- Name -->
      <MDBInput
        white
        v-model="form.name"
        name="name"
        label="Name"
        wrapper-class="my-4"
        required
      ></MDBInput>

      <!-- Phone Number -->
      <MDBInput white v-model="form.phone" label="Phone number" type="tel" wrapper-class="my-4" />

      <MDBTextarea
        white
        v-model="form.message"
        name="message"
        label="Your Message"
        wrapper-class="my-4"
      ></MDBTextarea>
    </ClientOnly>

    <!-- Submit button -->
    <MDBBtn :disabled="sending || formDisabled" color="primary" block type="submit">
      <MDBSpinner v-if="sending" size="sm" />
      <span v-else>Send Message</span>
    </MDBBtn>
  </form>
</template>

<script lang="ts" setup>
import { set } from "@vueuse/core";
import { MDBInput, MDBBtn, MDBTextarea, MDBAlert, MDBSpinner } from "mdb-vue-ui-kit";

const sent = ref(false);
const sending = ref(false);
const error = ref("");

const sentalert = ref(true);

const form = reactive({
  name: "",
  email: "",
  phone: "",
  message: "",
});

const formDisabled = computed(() => Object.values(form).every((v) => v === ""));
function submit() {
  set(sending, true);

  const { $authFetch } = useNuxtApp();
  $authFetch("/api/public/sendContactForm", {
    method: "POST",
    body: form,
  })
    .then(() => {
      set(sending, false);
      set(sent, true);
    })
    .catch((e) => {
      //FIXME: this doesn't seem to catch
      set(sending, false);
      set(error, e.message);
    });
}
</script>
